$(function(){
    var window_H = $(window).outerHeight(true),
        window_W = $(window).outerWidth();

    $(window).on('resize',function () {
        window_H = $(window).outerHeight(true),
        window_W = $(window).outerWidth(true);
    });

    var toolbar_H=$('.toolbar').outerHeight(true),
        top_H=$('.top').outerHeight(true)
    $(window).on('scroll',function(){
        var scroll_d=$(this).scrollTop(),
            floor_toolbar_H=Math.floor(toolbar_H);
        if(scroll_d>=floor_toolbar_H) {
            $('body').addClass('dockingTop');
            $('.pages > .main').css({'margin-top':top_H+'px' });
        } else {
            $('body').removeClass('dockingTop');
            $('.pages > .main').css({'margin-top': 0});
        }
    });

    if(window_W<=812) {
        $('.mm1--collapse').on('click touched',function(){
            $(this).toggleClass('on');
            $(this).children('.mm1__content').slideToggle(300).siblings('.mm1').find('.mm1__content').slideUp(300);
        });
    }

    $('[data-menu-switcher="open"]').on('click touched',function(){
        $('body').addClass('openMenu');
    });

    $('[data-menu-switcher="off"]').on('click touched',function(){
        $('body').removeClass('openMenu');
    });

    var $leafletSlider = $('[data-slick-slider="leaflet"]');
    $leafletSlider.on('init', function(event, slick){
        $(this).closest('.carousel').css({'visibility':'visible'})
    }).slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: false,
        dots: true,
        dotsClass: 'slides__dots slides__dots--above',
        rows: 0,
        prevArrow: '<button class="slick-prev slides__arrow slides__arrow--prev" aria-label="Previous" type="button"></button>',
        nextArrow: '<button class="slick-next slides__arrow slides__arrow--next" aria-label="Next" type="button"></button>'
    });
    if(window_W<=768) {
        $('[data-id="collapse-switch"]').on('click touched',function(){
            var $parent=$(this).closest('[data-id="collapse-group"]'),
                $self_children=$(this).next('[data-id="collapse-content"]');
            $siblings=$parent.siblings(),
                $other_children=$siblings.find('[data-id="collapse-content"]');

            $parent.toggleClass('on');
            $self_children.slideToggle();
            $siblings.removeClass('on');
            $other_children.slideUp();
        });
    }

    $('[data-id="go-top"]').on('click',function(){
		$('html,body').animate({ scrollTop: 0 }, 1200);
    });
});